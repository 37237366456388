import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="row footer">
      <div className="col-12 footer__content">
        <h3 className="footer__content_normal">
          Get in touch with me at
          <span className="footer__content_bold"> loki@lokih.com</span>
        </h3>
      </div>
    </div>
  );
}
